@import '~tachyons/css/tachyons';
@import './fonts';
@import './colors';
@import './line-awesome.min.css';
@import './layout';

:root {
  font-family: $font-family, sans-serif;
  font-size: 100%;
  font-stretch: $font-stretch;
  font-style: $font-style;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
}

body {
  min-height: 100vh;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}
