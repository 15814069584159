@import '../../../styles/colors';

$margin-offset: -5px;
$no-margin: 0;

.Resizer {
  background: $black;
  background-clip: padding-box;
  box-sizing: border-box;
  opacity: 0.2;
  z-index: 1;

  &:hover {
    transition: border 2s ease;
  }

  &.horizontal {
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    border-top: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    height: 11px;
    margin: $margin-offset $no-margin;
    width: 100%;

    &:hover {
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
      border-top: 5px solid rgba(0, 0, 0, 0.5);
    }
  }

  &.vertical {
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
    margin: $no-margin $margin-offset;
    width: 11px;

    &:hover {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      border-color: transparent;
    }
  }
}
