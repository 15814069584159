.t-mid {
  top: 50%;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.flex-1 {
  flex: 1;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.min-h1 {
  min-height: 1rem;
}

.min-h2 {
  min-height: 2rem;
}

.min-h3 {
  min-height: 4rem;
}

.min-h4 {
  min-height: 8rem;
}

.min-h5 {
  min-height: 16rem;
}

.min-vh-75 {
  min-height: 75vh;
}

@media (--breakpoint-not-small) {
  .min-h1-ns {
    min-height: 1rem;
  }

  .min-h2-ns {
    min-height: 2rem;
  }

  .min-h3-ns {
    min-height: 4rem;
  }

  .min-h4-ns {
    min-height: 8rem;
  }

  .min-h5-ns {
    min-height: 16rem;
  }
}

@media (--breakpoint-medium) {
  .min-h1-m {
    min-height: 1rem;
  }

  .min-h2-m {
    min-height: 2rem;
  }

  .min-h3-m {
    min-height: 4rem;
  }

  .min-h4-m {
    min-height: 8rem;
  }

  .min-h5-m {
    min-height: 16rem;
  }
}

@media (--breakpoint-medium) {
  .min-h1-l {
    min-height: 1rem;
  }

  .min-h2-l {
    min-height: 2rem;
  }

  .min-h3-l {
    min-height: 4rem;
  }

  .min-h4-l {
    min-height: 8rem;
  }

  .min-h5-l {
    min-height: 16rem;
  }
}
