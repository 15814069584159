$black: #000;
$blue: #0093e0;
$light-blue: #edf4fa;
$dark-blue: #222e38;
$ebony-clay: #1d242b;
$dark: #1d252c;
$blue-gray: #8ea7bb;
$light-blue-gray: #b0bdc8;
$dark-gray-blue: #304251;
$moon-gray: rgba(0, 0, 0, 0.4);
$outer-space: #31383e;
$light-outer-space: #3a4147;
$red: #d03f12;
$pink: #d91073;
$disabled-gray: #888;
$gray: #a5a5a5;
$light-gray: #f0f0f2;
$dark-gray: #bac2c9;
$white: #fff;
$success: #00e089;
$warning: #ffae00;
$light-botticelli: #dbe7f1;
$botticelli: #c9d6e1;
$cornflower: #90cee6;
$scorpion: #5f5f5f;
$catskill-white: #f6f9fb;
$white-blue: #e3e9ef;
$tropical-blue: #c3e5f7;
$spindle: #c0e0f1;

.success {
  color: $success;
}

.bg-success {
  background-color: $success;
}

.blue {
  color: $blue;
}

.bg-blue {
  background-color: $blue;
}

.b--blue {
  border-color: $blue;
}

.white {
  color: $white;
}

.bg-white {
  background-color: $white;
}

.b--white {
  border-color: $white;
}

.light-blue {
  color: $light-blue;
}

.bg-light-blue {
  background-color: $light-blue;
}

.b--light-blue {
  border-color: $light-blue;
}

.dark {
  color: $dark;
}

.bg-dark {
  background-color: $dark;
}

.b--dark {
  border-color: $dark;
}

.dark-blue {
  color: $dark-blue;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.b--dark-blue {
  border-color: $dark-blue;
}

.blue-gray {
  color: $blue-gray;
}

.bg-blue-gray {
  background-color: $blue-gray;
}

.b--blue-gray {
  border-color: $blue-gray;
}

.ebony-clay {
  color: $ebony-clay;
}

.bg-ebony-clay {
  background-color: $ebony-clay;
}

.b--ebony-clay {
  border-color: $ebony-clay;
}

.dark-gray-blue {
  color: $dark-gray-blue;
}

.bg-dark-gray-blue {
  background-color: $dark-gray-blue;
}

.b--dark-gray-blue {
  border-color: $dark-gray-blue;
}

.outer-space {
  color: $outer-space;
}

.bg-outer-space {
  background-color: $outer-space;
}

.b--outer-space {
  border-color: $outer-space;
}

.light-outer-space {
  color: $light-outer-space;
}

.bg-light-outer-space {
  background-color: $light-outer-space;
}

.b--light-outer-space {
  border-color: $light-outer-space;
}

.red {
  color: $red;
}

.bg-red {
  background-color: $red;
}

.b--red {
  border-color: $red;
}

.gray {
  color: $gray;
}

.bg-gray {
  background-color: $gray;
}

.b--gray {
  border-color: $gray;
}

.light-gray {
  color: $light-gray;
}

.bg-light-gray {
  background-color: $light-gray;
}

.b--light-gray {
  border-color: $light-gray;
}

.pink {
  color: $pink;
}

.bg-pink {
  background-color: $pink;
}

.b--pink {
  border-color: $pink;
}

.light-botticelli {
  color: $light-botticelli;
}

.bg-light-botticelli {
  background-color: $light-botticelli;
}

.b--light-botticelli {
  border-color: $light-botticelli;
}

.botticelli {
  color: $botticelli;
}

.bg-botticelli {
  background-color: $botticelli;
}

.b--botticelli {
  border-color: $botticelli;
}

.cornflower {
  color: $cornflower;
}

.bg-cornflower {
  background-color: $cornflower;
}

.b--cornflower {
  border-color: $cornflower;
}

.scorpion {
  color: $scorpion;
}

.bg-scorpion {
  background-color: $scorpion;
}

.b--scorpion {
  border-color: $scorpion;
}

.catskill-white {
  color: $catskill-white;
}

.bg-catskill-white {
  background-color: $catskill-white;
}

.b--catskill-white {
  border-color: $catskill-white;
}

.tropical-blue {
  color: $tropical-blue;
}

.bg-tropical-blue {
  background-color: $tropical-blue;
}

.b--tropical-blue {
  border-color: $tropical-blue;
}

.spindle {
  color: $spindle;
}

.bg-spindle {
  background-color: $spindle;
}

.b--spindle {
  border-color: $spindle;
}

.white-blue {
  color: $white-blue;
}

.bg-white-blue {
  background-color: $white-blue;
}

.b--white-blue {
  border-color: $white-blue;
}

.dark-gray {
  color: $dark-gray;
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.b--dark-gray {
  border-color: $dark-gray;
}

.light-blue-gray {
  color: $light-blue-gray;
}

.bg-light-blue-gray {
  background-color: $light-blue-gray;
}

.b--light-blue-gray {
  border-color: $light-blue-gray;
}

.disabled-gray {
  color: $disabled-gray;
}

.bg-disabled-gray {
  background-color: $disabled-gray;
}

.b--disabled-gray {
  border-color: $disabled-gray;
}
