@import '../../../styles/colors';

.icon {
  color: $white;
  font-size: 4.7rem;
}

.alert-button {
  border: 0;
  font-size: 0.8rem;
  margin: 0 1em;
  width: 6rem;
}

.button-ok {
  color: $white;
}

.button-cancel {
  background-color: $white;
}

.overlay.alert {
  .modal-content {
    height: 25rem;
    margin-bottom: 13rem;
    width: 30rem;
  }

  &.success {
    .icon {
      background-color: $success;
    }

    .button-ok {
      background-color: $success;
    }

    .button-cancel {
      border: 1px solid $success;
      color: $success;
    }
  }

  &.error {
    .icon {
      background-color: $pink;
    }

    .button-ok {
      background-color: $pink;
    }

    .button-cancel {
      border: 1px solid $pink;
      color: $pink;
    }
  }

  &.warning {
    .icon {
      background-color: $warning;
    }

    .button-ok {
      background-color: $warning;
    }

    .button-cancel {
      border: 1px solid $warning;
      color: $warning;
    }
  }
}
