$font-family: 'Poppins';
$font-weight: normal;
$font-style: normal;
$font-stretch: normal;
$line-height: normal;
$letter-spacing: normal;

@font-face {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  src:
    url('../fonts/Poppins-Regular.woff2') format('woff2'),
    url('../fonts/Poppins-Regular.woff') format('woff'),
    url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sansation';
  font-style: normal;
  font-weight: lighter;
  src:
    url('../fonts/Sansation-Light-webfont.woff2') format('woff2'),
    url('../fonts/Sansation-Light-webfont.woff') format('woff'),
    url('../fonts/Sansation-Light-webfont.ttf') format('truetype'),
    url('../fonts/Sansation-Light-webfont.eot?#iefix') format('embedded-opentype');
}
