@import '../../styles/colors';

$pause-icon: '\f2ad';

.SplitPane {
  .Pane {
    overflow-y: auto;
  }

  .Pane2 {
    overflow-y: hidden;
  }

  &.disable-pointer-events .Pane {
    pointer-events: none;
  }

  .Resizer {
    background-color: $botticelli;
    border: 0;
    font: normal normal normal 16px/1 LineAwesome;
    height: 0.7rem;
    opacity: 1;
    position: relative;

    &:hover {
      border: 0;
    }

    &::after {
      content: $pause-icon;
      left: 50%;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform: translate(-50%, -12%) rotate(90deg) scaleY(2);
      z-index: 9999;
    }
  }
}
