@import '../../../styles/colors';

.table-light.ReactTable {
  .rt-thead {
    .rt-tr {
      border-bottom: 0.1rem solid $botticelli;
      text-align: left;
    }

    .rt-th {
      border-right: 0;
      font-size: 0.7rem;
      font-weight: 600;
    }
  }

  .rt-tbody {
    .rt-tr-group {
      border-bottom: 0;

      .rt-tr {
        align-items: center;
        height: 2.3rem;

        .rt-td {
          border: 0;
          font-size: 0.8rem;
        }
      }
    }

    > :nth-child(even) {
      background-color: $catskill-white;
    }

    > :nth-child(odd) {
      background-color: $white;
    }
  }
}

.table-dark.ReactTable {
  $arrow-up: '\f122';
  $arrow-down: '\f11f';

  border: 0;
  text-align: center;

  .rt-tr {
    cursor: pointer;

    .rt-th {
      background-color: $botticelli;
      border: 0;
      color: $outer-space;
      font-size: 0.75rem;
      font-weight: 600;
      height: 2.3rem;
      padding: 0.8rem;
      text-transform: uppercase;
    }

    .rt-td {
      border: 0;
      color: $scorpion;
      font-size: 0.8rem;
      height: 2.3rem;
      padding: 0.7rem;
      text-transform: uppercase;
    }

    &:hover {
      .rt-td {
        background: $tropical-blue;
      }
    }
  }

  // TODO: Remove the width and max-width styles and set the "no-important" lint rule to error again
  // when the selectTable HOC accepts the select column width as a prop.
  // See: https://github.com/react-tools/react-table/issues/708

  .rt-thead .rt-tr .rt-th:first-child,
  .rt-tbody .rt-tr .rt-td:first-child {
    display: flex;
    justify-content: center;
    max-width: 50px !important;
    width: 50px !important;
  }

  .-sort-asc {
    :first-child {
      &::after {
        content: $arrow-up;
        font-family: LineAwesome, 'Courier New', Courier, monospace;
        margin-left: 0.3rem;
      }
    }
  }

  .-sort-desc {
    :first-child {
      &::after {
        content: $arrow-down;
        font-family: LineAwesome, 'Courier New', Courier, monospace;
        margin-left: 0.3rem;
      }
    }
  }

  .rt-tbody {
    > :nth-child(even) {
      background-color: $catskill-white;
    }

    > :nth-child(odd) {
      background-color: $white;
    }
  }
}
